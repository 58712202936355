<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Registro de ventas" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true" :tooltip="`El resumen funciona sólo para documentos aceptados en ${$t('generals.SII')}. No están considerados aquellos DTEs que estén pendientes de envío o rechazados.`" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasPermission(['view_document'])">
        <!-- <v-btn class="mr-2" @click="dialogExport = true" outlined :disabled="count === 0"><v-icon left size="20">mdi-export-variant</v-icon>Exportar</v-btn> -->
        <v-col style="max-width: 160px;" class="pa-0">
          <v-menu v-model="menuStartDay" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date.start" v-bind="attrs" v-on="on" prefix="desde" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
            </template>
            <v-date-picker v-model="date.start" @input="menuStartDay=false" no-title :first-day-of-week="1" type="date" locale="es" width="270" color="primary">
              <v-row class="mb-1 px-6" align="center" justify="end">
                <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { date.start=null }">Limpiar</v-btn>
              </v-row>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col style="max-width: 160px;" class="ml-2 pr-0">
          <v-menu v-model="menuEndDay" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280" z-index="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="pa-0" v-model="date.end" v-bind="attrs" v-on="on" prefix="hasta" append-icon="mdi-calendar-month" outlined single-line dense readonly hide-details />
            </template>
            <v-date-picker v-model="date.end" @input="menuEndDay=false" no-title type="date" locale="es" width="270" color="primary" :open-date="date.start" :min="date.start" :max="endDate">
              <v-row class="mb-1 px-6" align="center" justify="end">
                <v-btn class="body-1" :ripple="false" text color="primary" @click="() => { date.end=null }">Limpiar</v-btn>
              </v-row>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-btn @click="setQuery(true), getData()" color="primary" :loading="loading && !isCreated" class="ml-2">Actualizar</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col cols="12" class="px-0">
      <PermissionDenied v-if="!$helpers.hasPermission(['view_document'])" />
      <skeleton-list v-else-if="loading" />
      <template v-else>
        <v-data-table
          :class="{'fixed-table' : $store.state.base.isExpandNavigationDrawer}"
          :headers="headers"
          :items="list"
          hide-default-header
          disable-sort
          mobile-breakpoint
          hide-default-footer
          :items-per-page="pagination"
          no-data-text="No existen datos"
          style="cursor: pointer"
          @click:row="(list) => { $router.push({name: 'DocumentsList', query: {document_type: list.document_type, status__code: 8, date__gte: $route.query.start, date__lte: $route.query.end}}).catch(() => {}) }"
        >
          <!-- header -->
          <template v-slot:header="{ props: { headers } }">
            <VTableHeaders :headers="headers" @sort="setSort" />
            <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px; min-width: 100%" />
          </template>
          <!-- end header -->
          <template v-slot:[`item.document_type`]="{item}">
            <span class="body-2" style="white-space: normal !important">{{item.document_type | nameDocumentsType}}</span>
          </template>
          <template v-slot:[`item.count`]="{item}">
            <span class="body-2" style="white-space: normal !important">{{item.count | number}}</span>
          </template>
          <template v-slot:[`item.total`]="{item}">
            <span class="body-2">{{item.total | currency(item.document_type)}}</span>
            <span class="fontDraw--text caption ml-1">
              <template v-if="Object.keys(((item || {}).exchange || {})).length">{{item.exchange.currency_from || item.exchange.currency_to || item.exchange.currency}}</template>
              <template v-if="!['CL110', 'CL111', 'CL112'].includes(item.document_type)">{{item.document_type | firstDocumentsTypeCurrency}}</template>
              <template v-else>CLP</template>
            </span>
          </template>
          <template v-slot:[`item.exempt`]="{item}">
            <span class="body-2" style="white-space: normal !important">{{item.exempt | number}}</span>
            <span class="fontDraw--text caption ml-1">
              <template v-if="!['CL110', 'CL111', 'CL112'].includes(item.document_type)">{{item.document_type | firstDocumentsTypeCurrency}}</template>
              <template v-else>CLP</template>
            </span>
          </template>
          <template v-slot:[`item.net`]="{item}">
            <span class="body-2" style="white-space: normal !important">{{item.net | number}}</span>
            <span class="fontDraw--text caption ml-1">
              <template v-if="!['CL110', 'CL111', 'CL112'].includes(item.document_type)">{{item.document_type | firstDocumentsTypeCurrency}}</template>
              <template v-else>CLP</template>
            </span>
          </template>
          <template v-slot:[`item.tax`]="{item}">
            <span class="body-2" style="white-space: normal !important">{{item.tax | number}}</span>
            <span class="fontDraw--text caption ml-1">
              <template v-if="!['CL110', 'CL111', 'CL112'].includes(item.document_type)">{{item.document_type | firstDocumentsTypeCurrency}}</template>
              <template v-else>CLP</template>
            </span>
          </template>
        </v-data-table>
      </template>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import ListViewMixin from '@/mixins/ListViewMixin'
import MiniHeader from '@/components/commons/MiniHeader'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import DocumentTypes from '@/collections/documentTypes'

export default {
  components: {
    SkeletonList,
    MiniHeader,
    PermissionDenied,
    VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    isCreated: true,
    scroll: 0,
    date: {
      start: null,
      end: null
    },
    menuStartDay: false,
    menuEndDay: false,
    loading: false,
    filters: [
      {
        type: 'date',
        id: 'start',
        id2: 'end',
        label: 'Fecha',
        value: null,
        value2: null,
        isSimpleOperators: true,
        operator: 'rango',
        countries: ['PE', 'CL']
      }
    ],
    documentsList: [],
    breadcrumbs: {
      main: 'Reporte',
      children: [
        {
          text: 'Registro de ventas'
        }
      ]
    },
    headers: [
      { text: 'Tipo de documento', value: 'document_type', sortable: true },
      { text: 'Total de doc', value: 'count', align: 'end', sortable: true },
      { text: 'Monto exento', value: 'exempt', align: 'end', sortable: true },
      { text: 'Monto neto', value: 'net', align: 'end', sortable: true },
      { text: 'Monto IVA', value: 'tax', align: 'end', sortable: true },
      { text: 'Monto total', value: 'total', align: 'end', sortable: true}
    ],
    documentTypes: DocumentTypes
  }),
  computed: {
    list () {
      if (this.$route.query.ordering) return this.sortBy()
      return this.documentsList
    },
    endDate () {
      return this.date.start ? moment(this.date.start).endOf('month').format('YYYY-MM-DD') : null
    }
  },
  watch: {
    $route: {
      handler (val) {
        if (!Object.keys(val.query).includes('ordering')) {
          this.date = {
            start: val.query.start,
            end: val.query.end
          }
        }
      },
      inmediate: false,
      deep: false
    },
    'date.start' () {
      this.date.end = null
    }
  },
  async created () {
    if (this.$helpers.hasPermission(['view_document'])) {
      if (!Object.keys(this.$route.query).length) this.setQuery()

      this.date = {
        start: this.$route.query.start,
        end: this.$route.query.end
      }
      await this.getData()
      this.isCreated = false
    }
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    setQuery (isFilter = false) {
      let startDay = isFilter ? this.date.start : moment().startOf('month').format('YYYY-MM-DD')
      let endDay = isFilter ? this.date.end : moment().endOf('month').format('YYYY-MM-DD')

      this.$router.replace({ query: {...(startDay && { start: startDay }), ...(endDay && { end: endDay })}}).catch(err => err)
    },
    async getData () {
      this.documentsList = []
      let array = []
      this.loading = true
      array = await this.getDocumentsList()
      this.loading = false

      let ordenList = this.documentTypes.filter(({ country }) => country === this.$store.getters['base/currentCountry']).map(({ id }) => id)
      ordenList.forEach((item) => {
        let found = array.find(obj => obj.document_type === item)
        if (found) this.documentsList.push(found)
      })
    },
    async getDocumentsList () {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (Object.keys(this.$route.query).includes('ordering')) {
        delete query.ordering
      }
      const currentDocumentType = this.documentTypes.filter(({ country }) => country === this.$store.getters['base/currentCountry'])
      let array = []
      await Promise.all(currentDocumentType.map(async (item) => {
        try {
          const response = await this.$store.dispatch('documents/LIST', {
            resource: 'metrics/document_summary/summary_by_document_type',
            query: {
              ...query,
              document_type: item.id
            }
          })
          const { count, exempt, total, net, tax } = response.data
          if (count === 0 && exempt === 0 && total === 0 && net === 0 && tax === 0) return false

          array = [...array, response.data]
        } catch (e) {
          return []
        }
      }))
      return array ?? []
    },
    setSort ($event) {
      if (this.$route.query.ordering === $event) {
        $event = `-${$event}`
      }
      this.$router.replace({name: this.$route.name, query: Object.assign({}, this.$route.query, {ordering: $event})}).catch(err => err)
    },
    sortBy () {
      const type = !this.$route.query.ordering.search('-')
      const value = type ? this.$route.query.ordering.slice(1) : this.$route.query.ordering
      return orderBy(this.documentsList, [value], [!type ? 'asc' : 'desc'])
    }
  }
}
</script>